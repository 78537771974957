import * as S from './DateSelector.styled';
import { addDays, isValid, subDays } from 'date-fns';
import { useRef, useState } from 'react';

import { Header } from './Header/Header';
import { Calendar } from './Calendar/Calendar';
import { COMPONENT_IDS } from '@ntb-sport/constants';

export interface DateSelectorProps {
  date: string | Date;
  onDateSelected: (date: Date) => void;
  onMonthSelected?: (offset: number) => void;
  locale?: string;
  daysWithEventsInMonth?: any;
  isLoadingDaysWithEventsInMonth?: boolean;
  offset?: number;
  translations?: {
    previousDay: string;
    nextDay: string;
    previousMonth: string;
    nextMonth: string;
    months: string[];
    weekdays: string[];
    today: string;
  };
}

export const DateSelector = ({
  date,
  onDateSelected,
  onMonthSelected,
  daysWithEventsInMonth,
  isLoadingDaysWithEventsInMonth,
  locale,
  offset,
  translations = {
    previousDay: 'Forrige dag',
    nextDay: 'Neste dag',
    previousMonth: 'Forrige måned',
    nextMonth: 'Neste måned',
    months: [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
    weekdays: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'],
    today: 'I dag',
  },
}: DateSelectorProps) => {
  const toggleDatepickerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onHandlePrevDate = () => {
    onDateSelected(subDays(new Date(date), 1));
  };

  const onHandleNextDate = () => {
    onDateSelected(addDays(new Date(date), 1));
  };

  const onHandleDateSelected = (e: { date: Date }) => {
    onDateSelected(e.date);
    setIsOpen(false);
  };

  const onToggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const onClickToday = () => {
    onDateSelected(new Date());
    setIsOpen(false);
  };

  if (!isValid(new Date(date))) return null;

  return (
    <S.Container data-component-id={COMPONENT_IDS.DATE_SELECTOR}>
      <Header
        date={new Date(date)}
        translations={translations}
        isOpen={isOpen}
        locale={locale}
        toggleDatepickerRef={toggleDatepickerRef}
        onToggleDatePicker={onToggleDatePicker}
        onHandlePrevDate={onHandlePrevDate}
        onHandleNextDate={onHandleNextDate}
      />

      <Calendar
        isOpen={isOpen}
        date={new Date(date)}
        translations={translations}
        selected={new Date(date)}
        offset={offset}
        onDateSelected={onHandleDateSelected}
        onClickToday={onClickToday}
        onOffsetChanged={onMonthSelected}
        firstDayOfWeek={1}
        daysWithEventsInMonth={daysWithEventsInMonth}
        isLoadingDaysWithEventsInMonth={isLoadingDaysWithEventsInMonth}
      />
    </S.Container>
  );
};
