import * as S from './FootballTeam.styled';

import List from './List/List';
import { ELEMENT_IDS, MATCH_EVENTS } from '@ntb-sport/constants';
import Pitch from './Pitch/Pitch';

import { useState } from 'react';
import { BsListUl } from 'react-icons/bs';
import { GiSoccerField } from 'react-icons/gi';

import { ButtonGroup, IconButton } from '../../../chakra';

export const Team = ({
  team,
  matchEvents,
  onClickPerson,
  displayNationality,
  matchTimestamp,
  isNationalTeam,
  ...rest
}: {
  team: any;
  matchEvents: any;
  matchTimestamp: string;
  displayNationality?: boolean;
  isNationalTeam: boolean;
  onClickPerson: any;
}) => {
  const [view, setView] = useState('formation');

  if (!team?.persons?.length) {
    return (
      <S.LineupNotAvailable>
        Lagoppstilling ikke tilgjengelig
      </S.LineupNotAvailable>
    );
  }

  const persons = team.persons.reduce((persons: any, person: any) => {
    const personId = person.personId;
    const personEvents = matchEvents
      ? matchEvents.reduce((events: any, event: any) => {
          if (event.person && event.person.id === personId) {
            if (event.matchEventTypeId === MATCH_EVENTS.GOAL) {
              if (!events.goals) events.goals = [];
              events.goals.push(event);
            } else if (event.matchEventTypeId === MATCH_EVENTS.OWN_GOAL) {
              if (!events.ownGoals) events.ownGoals = [];
              events.ownGoals.push(event);
            } else if (event.matchEventTypeId === MATCH_EVENTS.ASSIST) {
              if (!events.assists) events.assists = [];
              events.assists.push(event);
            } else if (
              event.matchEventTypeId === MATCH_EVENTS.INDIRECT_ASSIST
            ) {
              if (!events.indirectAssists) events.indirectAssists = [];
              events.indirectAssists.push(event);
            } else if (
              event.matchEventTypeId === MATCH_EVENTS.YELLOW_CARD ||
              event.matchEventTypeId === MATCH_EVENTS.SECOND_YELLOW_CARD
            ) {
              if (!events.yellowCards) events.yellowCards = [];
              events.yellowCards.push(event);
            } else if (event.matchEventTypeId === MATCH_EVENTS.RED_CARD) {
              if (!events.redCards) events.redCards = [];
              events.redCards.push(event);
            } else if (
              event.matchEventTypeId === MATCH_EVENTS.PLAYER_SUBSTITUTED
            ) {
              if (!events.substituteOff) events.substituteOff = [];
              events.substituteOff.push(event);
            } else if (event.matchEventTypeId === MATCH_EVENTS.SUBSTITUTE_ON) {
              if (!events.substituteOn) events.substituteOn = [];
              events.substituteOn.push(event);
            } else if (event.matchEventTypeId === MATCH_EVENTS.PENALTY_MISS) {
              if (!events.penaltyMiss) events.penaltyMiss = [];
              events.penaltyMiss.push(event);
            }
          }

          return events;
        }, {})
      : [];

    if (!persons[personId]) {
      persons[personId] = { ...person, personEvents };
    }

    return persons;
  }, {});

  const hasFormation =
    Object.values(persons).filter(
      (person: any) =>
        person.startsMatch &&
        person.position.id !== 2 &&
        person.position.id !== 6 &&
        person.position.id !== 13 &&
        person.position.id !== 11,
    ).length === 11;

  return (
    <S.Team {...rest}>
      {hasFormation && (
        <ButtonGroup isAttached display="flex" justifyContent="center" mb={4}>
          <IconButton
            data-element-id={ELEMENT_IDS.BUTTON}
            aria-label="Vis som formasjon"
            title="Vis som formasjon"
            icon={<GiSoccerField fontSize="var(--ntb-fontSizes-lg)" />}
            size="sm"
            onClick={() => setView('formation')}
            isActive={view === 'formation'}
            backgroundColor="var(--background-color)"
            borderWidth="1px"
            borderColor="var(--border-color)"
            _hover={{
              bg: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              bg: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
          >
            Formasjon
          </IconButton>
          <IconButton
            data-element-id={ELEMENT_IDS.BUTTON}
            aria-label="Vis som liste"
            title="Vis som liste"
            icon={<BsListUl fontSize="var(--ntb-fontSizes-lg)" />}
            size="sm"
            onClick={() => setView('list')}
            isActive={view === 'list'}
            backgroundColor="var(--background-color)"
            color={'var(--font-color)'}
            borderWidth="1px"
            borderColor="var(--border-color)"
            _hover={{
              bg: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              bg: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
          >
            Liste
          </IconButton>
        </ButtonGroup>
      )}
      {hasFormation && view === 'formation' ? (
        <Pitch persons={persons} kit={team.kit} onClickPerson={onClickPerson} />
      ) : (
        <List
          persons={persons}
          team={team}
          displayNationality={displayNationality}
          onClickPerson={onClickPerson}
          isNationalTeam={isNationalTeam}
        />
      )}
    </S.Team>
  );
};

export default Team;
