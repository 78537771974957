import { UUIDs } from 'neo-common-enums';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface GenderProps {
  gender: string;
}
export interface StyledProps {
  $gender: string;
}

const StyledGender = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props?.$gender === 'gender-male' || props?.$gender === 'men'
      ? '#0059B3'
      : '#E51C30'};
  width: var(--ntb-space-4);
  height: var(--ntb-space-4);
  border-radius: 3px;
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-bold);

  color: #fff;
`;

export function Gender({ gender }: GenderProps) {
  return gender === UUIDs.Gender.MIXED ? (
    <>
      <StyledGender aria-label='menn' $gender={UUIDs.Gender.MALE}>M</StyledGender>
      <StyledGender aria-label='kvinner' $gender={UUIDs.Gender.FEMALE}>K</StyledGender>
    </>
  ) : (
    <StyledGender aria-label={(gender === 'gender-male' || gender === 'men') ? 'menn' : 'kvinner'} $gender={gender}>
      {gender === 'gender-male' || gender === 'men' ? 'M' : 'K'}
    </StyledGender>
  );
}

export default Gender;
