import CountryFlag from '../../common/CountryFlag/CountryFlag';
import * as S from './EventParticipant.styled';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import {
  NeoLineupExchangerType,
  NeoPersonType,
  NeoRepresentingType,
  NeoTeamType,
} from '@ntb-sport/types';

interface EventParticipantType {
  team?: NeoTeamType;
  person?: NeoPersonType;
  lineup?: any;
  lineupExchanger?: NeoLineupExchangerType;
  representing?: NeoRepresentingType;
  bibNumber?: number;
  displayBibNumber?: boolean;
  displayFullName?: boolean;
  displayInline?: boolean;
  isPelotonParticipant?: boolean;
}

export const EventParticipant = ({
  team,
  person,
  lineup,
  lineupExchanger,
  representing,
  bibNumber,
  displayBibNumber = true,
  displayFullName = false,
  displayInline = false,
  isPelotonParticipant,
}: EventParticipantType) => {
  const country =
    team?.countries?.[0] ||
    team?.country ||
    person?.country ||
    person?.countries?.[0];
  const countryName = !isPelotonParticipant && country?.name;
  const countryCode = country?.names?.find(
    (name: { nameTypeId: number }) => name?.nameTypeId === 25,
  )?.name;

  return (
    <S.Participant>
      <CountryFlag countryCode={countryCode} size="xs" />
      <S.Container $displayInline={displayInline}>
        <S.Names>
          {displayBibNumber && bibNumber && (
            <S.BibNumber>{bibNumber}</S.BibNumber>
          )}
          {displayFullName ? (
            <S.PrimaryName>{team?.name || person?.name}</S.PrimaryName>
          ) : (
            <>
              <S.PrimaryName>
                {team?.name || person?.lastName || person?.name}
              </S.PrimaryName>
              {person?.firstName && (
                <S.SecondaryName>{person?.firstName}</S.SecondaryName>
              )}
            </>
          )}
        </S.Names>

        <S.TertiaryName $displayInline={displayInline}>
          {representing
            ? representing?.name
            : lineup
              ? lineup?.person?.name ||
                lineup?.map((participant: any, index: number) => (
                  <S.Lineup key={index}>
                    {participant?.number
                      ? `${participant?.number}. ${participant?.person?.name}`
                      : participant?.person?.name}
                  </S.Lineup>
                ))
              : countryName}
          {lineupExchanger && (
            <S.Exchanger>
              {' '}
              <ArrowForwardIcon /> {lineupExchanger?.person?.name}
            </S.Exchanger>
          )}
        </S.TertiaryName>
      </S.Container>
    </S.Participant>
  );
};

export default EventParticipant;
