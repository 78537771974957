import styled from 'styled-components';

export const MonthPicker = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  grid-column-gap: var(--ntb-space-4);
  margin-bottom: var(--ntb-space-4);
`;
