import styled from 'styled-components';

export const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  grid-column-gap: var(--ntb-space-2);
  margin-bottom: var(--ntb-space-2);
`;

export const Weekday = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
`;
