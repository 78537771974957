import { darken, linearGradient } from 'polished';

import styled from 'styled-components';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $teamBackgroundColor?: string;
  $teamTextColor?: string;
  $src?: string;
}

export const Container = styled.div<StyledProps>`
  container-type: inline-size;
  container-name: container;
  background: ${(props) =>
    props?.$teamBackgroundColor
      ? linearGradient({
          colorStops: [
            `${props?.$teamBackgroundColor} 0%`,
            `${darken(0.1, props?.$teamBackgroundColor)} 100%`,
          ],
          toDirection: 'to bottom right',
          fallback: props?.$teamBackgroundColor,
        })
      : 'var(--background-color)'};
  box-shadow: var(--border-color) 0px -1px 0px inset;
`;

export const Hero = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeroContent = styled.div`
  display: flex;
  align-items: center;
  padding: var(--ntb-space-2);
  column-gap: var(--ntb-space-4);
`;

export const ProfileImageWrapper = styled.div<StyledProps>`
  width: 70px;
  height: 70px;
  border-style: solid;
  border-width: 5px;
  background-color: #fff;
  border-color: ${(props) =>
    props?.$teamBackgroundColor
      ? darken(0.1, props?.$teamBackgroundColor)
      : 'var(--border-color)'};
  border-radius: var(--ntb-radii-full);

  @container container ${MEDIA_QUERY.TABLET} {
    width: 120px;
    height: 120px;
  }
`;

export const ProfileImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--ntb-radii-full);
`;

export const Bio = styled.div``;

export const Name = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-2xl);
  font-weight: var(--ntb-fontWeights-medium);
  margin: 0 0 var(--ntb-space-1) 0;
  color: ${(props) => props?.$teamTextColor || 'var(--font-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-3xl);
  }
`;

export const Team = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const Names = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;

  @container container ${MEDIA_QUERY.TABLET} {
    align-items: center;
    flex-direction: row;
    column-gap: var(--ntb-space-1);
  }
`;

export const CurrentTeamName = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-sm);
  color: ${(props) => props?.$teamTextColor || 'var(--font-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-xl);
  }
`;

export const LogoWrapper = styled.div<StyledProps>`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: var(--ntb-radii-full);
  border-style: solid;
  border-width: 2px;
  padding: var(--ntb-space-1);
  border-color: ${(props) =>
    props?.$teamBackgroundColor
      ? darken(0.1, props?.$teamBackgroundColor)
      : 'var(--border-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    width: 44px;
    height: 44px;
  }
`;

export const TeamLogo = styled.img`
  object-fit: cover;
  max-width: 100%;
`;

export const OwnedByTeam = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  column-gap: var(--ntb-space-1);
  color: ${(props) =>
    props?.$teamTextColor ? props?.$teamTextColor : 'var(--font-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const OwnedByTeamLabel = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  color: ${(props) =>
    props?.$teamTextColor ? props?.$teamTextColor : 'var(--font-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const OwnedByTeamName = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  color: ${(props) =>
    props?.$teamTextColor ? props?.$teamTextColor : 'var(--font-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Position = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);
  color: ${(props) =>
    props?.$teamTextColor ? props?.$teamTextColor : 'var(--font-color)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
