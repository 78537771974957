import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import * as S from './Header.styled';
import { format } from 'date-fns/format';

import { nb, fi, sv } from 'date-fns/locale';
import { ELEMENT_IDS } from '@ntb-sport/constants';

import { IconButton, Button } from '../../../chakra';

const LOCALES = {
  nb,
  sv,
  fi,
};

interface HeaderProps {
  date: Date;
  translations: any;
  toggleDatepickerRef: any;
  isOpen: boolean;
  locale?: string;
  onHandlePrevDate: any;
  onToggleDatePicker: any;
  onHandleNextDate: any;
}

export const Header = ({
  date,
  translations,
  toggleDatepickerRef,
  isOpen,
  locale = 'nb',
  onHandlePrevDate,
  onToggleDatePicker,
  onHandleNextDate,
}: HeaderProps) => {
  return (
    <S.Header data-element-id={ELEMENT_IDS.HEADER}>
      <IconButton
        data-element-id={ELEMENT_IDS.BUTTON}
        onClick={onHandlePrevDate}
        aria-label={translations.previousDay}
        size="sm"
        icon={<ChevronLeftIcon />}
        borderWidth="1px"
        backgroundColor="var(--background-color)"
        borderColor="var(--border-color)"
        color="var(--font-color)"
        _hover={{
          bg: 'var(--background-color__hover)',
          color: 'var(--font-color__hover)',
        }}
        _active={{
          bg: 'var(--background-color__active)',
          color: 'var(--font-color__active)',
        }}
      />

      <Button
        data-element-id={ELEMENT_IDS.BUTTON}
        size="sm"
        ref={toggleDatepickerRef}
        aria-controls="calendar"
        aria-expanded={isOpen}
        onClick={onToggleDatePicker}
        borderWidth="1px"
        backgroundColor="var(--background-color)"
        borderColor="var(--border-color)"
        color="var(--font-color)"
        _hover={{
          bg: 'var(--background-color__hover)',
          color: 'var(--font-color__hover)',
        }}
        _active={{
          bg: 'var(--background-color__active)',
          color: 'var(--font-color__active)',
        }}
      >
        <S.Date>
          {format(new Date(date), 'EEEE d. MMM yyyy', {
            locale: LOCALES[locale as keyof typeof LOCALES],
          })}
        </S.Date>
      </Button>

      <IconButton
        data-element-id={ELEMENT_IDS.BUTTON}
        onClick={onHandleNextDate}
        aria-label={translations.nextDay}
        size="sm"
        icon={<ChevronRightIcon />}
        borderWidth="1px"
        backgroundColor={`var(--background-color)`}
        color={`var(--font-color)`}
        borderColor={`var(--border-color)`}
        _hover={{
          bg: 'var(--background-color__hover)',
          color: 'var(--font-color__hover)',
        }}
        _active={{
          bg: 'var(--background-color__active)',
          color: 'var(--font-color__active)',
        }}
      />
    </S.Header>
  );
};
