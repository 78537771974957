import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import AudioAnimation from './AudioAnimation';
import * as S from './RadioButton.styled';

interface MediaPlayerButtonProps {
  onClick: () => void;
  isPlaying: boolean;
  competitionId?: string | number;
}

export const RadioButton = ({
  onClick,
  isPlaying,
  competitionId,
}: MediaPlayerButtonProps) => (
  <S.Container
    data-component-id={COMPONENT_IDS.RADIO_HERO}
    data-competition-id={competitionId}
    data-tournament-id={competitionId}
  >
    <S.RadioButton onClick={onClick}>
      {isPlaying ? (
        <>
          <AudioAnimation /> <span>Hører på</span>
        </>
      ) : (
        <span>Hør på</span>
      )}
      <S.RadioLogo />
      <S.RadioButtonIcon data-element-id={ELEMENT_IDS.ICON}>
        {isPlaying ? (
          <svg
            aria-hidden="true"
            width="1.500em"
            height="1.500em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 3H3v18h7V3zm11 0h-7v18h7V3z"
            ></path>
          </svg>
        ) : (
          <svg
            aria-hidden="true"
            width="1.500em"
            height="1.500em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M6 2h8l5.5 10L14 22H6l5.5-10L6 2z"
            ></path>
          </svg>
        )}
      </S.RadioButtonIcon>
    </S.RadioButton>
  </S.Container>
);

export default RadioButton;
