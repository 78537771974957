import styled, { css } from 'styled-components';
import CountryFlag from '../../../common/CountryFlag/CountryFlag';

interface StyledProps {
  $type?: any;
}

export const Event = styled.div`
  display: grid;
  grid-template-areas:
    'status name broadcast'
    'childEvents childEvents childEvents';
  grid-template-columns: 34px auto max-content;
  grid-column-gap: var(--ntb-space-4);
  align-items: center;
  padding: var(--ntb-space-2);
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-color);
  background-color: var(--background-color);

  &:hover {
    background-color: var(--background-color__hover);
  }
`;

export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

export const EventNameAndGender = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const EventName = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const LocationAndDays = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const EventLocation = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
  color: var(--base-colors-blackAlpha-700);
`;

export const EventDays = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
  color: var(--base-colors-blackAlpha-700);
`;

export const StatusInfo = styled.span<StyledProps>`
  color: ${(props) =>
    props?.$type === 'error'
      ? 'var(--base-colors-error)'
      : 'var(--base-colors-success)'};
`;

export const HasAthletesFromCountry = styled(CountryFlag)`
  min-width: auto;
  max-height: 16px;
`;

export const StatusContainer = styled.div<StyledProps>`
  grid-area: status;
  justify-self: center;
`;

export const Status = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  color: var(--base-colors-blackAlpha-700);

  ${(props) =>
    props.$type === 'success' &&
    css`
      color: var(--base-colors-success);
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: var(--base-colors-success);
        border-radius: 50%;
      }
    `}

  ${(props) =>
    props.$type === 'error' &&
    css`
      color: var(--base-colors-error);
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: var(--base-colors-error);
        border-radius: 50%;
      }
    `}
`;

export const Broadcast = styled.div`
  grid-area: broadcast;
  display: flex;
  flex-direction: column;
  column-gap: var(--ntb-space-2);
  row-gap: var(--ntb-space-2);
`;

export const BroadcastChannel = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  text-align: right;
  color: var(--base-colors-blackAlpha-900);
  font-weight: var(--ntb-fontWeights-light);
`;
