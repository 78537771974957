import { LabelProperties } from 'neo-common-enums';
import RadioButton from '../../../common/RadioButton/RadioButton';
import { NrkRadioPlayer } from '../../../common/MediaPlayer/NrkRadioPlayer';

interface EventHeroRadioType {
  streams: any;
  radioId?: string;
  onClick: (radioId: string | undefined) => void;
  competitionId: string | number;
}

export const EventHeroRadio = ({
  streams,
  radioId,
  onClick,
  competitionId,
}: EventHeroRadioType) => {
  return (
    <>
      {Object.values<any>(streams?.[LabelProperties.BroadcastType.RADIO])?.map(
        (broadcast: any) => (
          <RadioButton
            key={broadcast?.uuid}
            isPlaying={radioId === broadcast?.streamId}
            onClick={() =>
              radioId ? onClick(undefined) : onClick(broadcast?.streamId)
            }
            competitionId={competitionId}
          />
        ),
      )}
      {radioId && (
        <NrkRadioPlayer videoId={radioId} onClose={() => onClick(undefined)} />
      )}
    </>
  );
};
