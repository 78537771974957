import * as S from './Weekdays.styled';

interface WeekdaysProps {
  weekdays: string[];
  month: number;
  year: number;
}

export const Weekdays = ({ weekdays, month, year }: WeekdaysProps) => {
  return (
    <S.Weekdays>
      {weekdays.map((weekday) => (
        <S.Weekday key={`${month}${year}${weekday}`}>{weekday}</S.Weekday>
      ))}
    </S.Weekdays>
  );
};
