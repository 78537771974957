import { Button } from '../../../../chakra';
import { ELEMENT_IDS, SPORT_ID_TO_NORWEGIAN_NAME } from '@ntb-sport/constants';
import { format } from 'date-fns/format';
import * as S from './Days.styled';
import { DateObj } from 'dayzed';

interface DaysProps {
  weeks: Array<Array<DateObj | ''>>;
  month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  year: number;
  daysWithEventsInMonth: any;
  isLoadingDaysWithEventsInMonth?: boolean;
  getDateProps: any;
}

export const Days = ({
  weeks,
  month,
  year,
  daysWithEventsInMonth,
  isLoadingDaysWithEventsInMonth,
  getDateProps,
}: DaysProps) => {
  return (
    <S.Days>
      {weeks.map((week: any, weekIndex: number) =>
        week.map((dateObj: DateObj, index: number) => {
          const key = `${month}${year}${weekIndex}${index}`;
          if (!dateObj) {
            return <div key={key} />;
          }
          const { date, selected }: { date: Date; selected: boolean } = dateObj;

          const dayInfo = daysWithEventsInMonth?.find(
            (d: any) => d?.date === format(date, 'yyyy-MM-dd'),
          );
          const sports = dayInfo?.sports
            ?.reduce((acc: string[], item: { uuid: string }) => {
              acc.push(SPORT_ID_TO_NORWEGIAN_NAME[item?.uuid]);
              return acc;
            }, [])
            ?.toString();

          const isValidDate = daysWithEventsInMonth ? Boolean(dayInfo) : true;

          return (
            <Button
              {...getDateProps({ dateObj })}
              data-element-id={ELEMENT_IDS.BUTTON}
              isActive={selected}
              borderWidth={isValidDate && '1px'}
              borderColor="var(--border-color)"
              backgroundColor={
                isValidDate ? 'var(--background-color)' : 'transparent'
              }
              color="var(--font-color)"
              _hover={{
                bg: 'var(--background-color__hover)',
                borderColor: 'var(--border-color__hover)',
                color: 'var(--font-color__hover)',
              }}
              _active={{
                bg: 'var(--background-color__active)',
                borderColor: 'var(--border-color__active)',
                color: 'var(--font-color__active)',
              }}
              size="sm"
              isLoading={isLoadingDaysWithEventsInMonth}
              isDisabled={!isValidDate}
              w="100%"
              key={key}
            >
              {date.getDate()}
            </Button>
          );
        }),
      )}
    </S.Days>
  );
};
