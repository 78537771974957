import * as S from './HandballTeam.styled';

import { CardIcon } from '../../../icons';
import { MATCH_EVENTS } from '@ntb-sport/constants';

import { Fragment } from 'react';
import { Container, Tooltip } from '../../../chakra';

const HandballTeam = ({
  team,
  matchEvents,
  displayNationality,
}: {
  team: any;
  matchEvents: any;
  displayNationality?: boolean;
}) => {
  return (
    <Container>
      <S.Persons>
        {team?.persons?.map((person: any, index: number) => {
          const goals = matchEvents.filter(
            (event: any) =>
              event.matchEventTypeId === MATCH_EVENTS.GOAL &&
              event.person &&
              event.person.id === person.personId,
          );
          const penalties = matchEvents.filter(
            (event: any) =>
              event.matchEventTypeId === MATCH_EVENTS.PENALTY &&
              event.person &&
              event.person.id === person.personId,
          );

          const yellowCards = matchEvents.filter(
            (event: any) =>
              event.matchEventTypeId === MATCH_EVENTS.YELLOW_CARD &&
              event.person &&
              event.person.id === person.personId,
          );

          const twoMinutesSuspension = matchEvents.filter(
            (event: any) =>
              event.matchEventTypeId === MATCH_EVENTS.TWO_MINUTES_SUSPENSION &&
              event.person &&
              event.person.id === person.personId,
          );

          const redCards = matchEvents.filter(
            (event: any) =>
              event.matchEventTypeId === MATCH_EVENTS.RED_CARD &&
              event.person &&
              event.person.id === person.personId,
          );

          const blueCards = matchEvents.filter(
            (event: any) =>
              event.matchEventTypeId === MATCH_EVENTS.BLUE_CARD &&
              event.person &&
              event.person.id === person.personId,
          );
          return (
            <Fragment key={person.personId}>
              {index === 0 && (
                <S.Person>
                  <S.StatTitle></S.StatTitle>
                  <S.StatsHeader>
                    <S.StatHeader>
                      <Tooltip hasArrow label="Mål" aria-label="Mål">
                        M
                      </Tooltip>
                    </S.StatHeader>
                    <S.StatHeader>
                      <Tooltip
                        hasArrow
                        label="Mål på straffekast"
                        aria-label="Mål på straffekast"
                      >
                        7
                      </Tooltip>
                    </S.StatHeader>
                    <S.StatHeader>
                      <Tooltip
                        hasArrow
                        label="To minuttere"
                        aria-label="To minuttere"
                      >
                        2
                      </Tooltip>
                    </S.StatHeader>
                    <S.StatHeader>
                      <Tooltip hasArrow label="Kort" aria-label="Kort">
                        Kort
                      </Tooltip>
                    </S.StatHeader>
                  </S.StatsHeader>
                </S.Person>
              )}
              <S.Person>
                <S.Bio>
                  <S.ShirtNumber>{person.shirtNumber}</S.ShirtNumber>
                  <S.NameAndCountry>
                    <S.Name>{person.name}</S.Name>
                    {displayNationality && (
                      <S.Country>
                        <S.Flag countryName={person.country.name} />
                        <S.CountryName>{person.country.name}</S.CountryName>
                      </S.Country>
                    )}
                  </S.NameAndCountry>
                </S.Bio>

                <S.Stats>
                  <S.Stat>{goals.length}</S.Stat>
                  <S.Stat>{penalties.length}</S.Stat>
                  <S.Stat>{twoMinutesSuspension.length}</S.Stat>
                  <S.Stat>
                    {yellowCards.length ? (
                      <CardIcon
                        cardId="yellow"
                        fillColor="var(--warning-color)"
                      />
                    ) : redCards.length ? (
                      <CardIcon cardId="red" fillColor="var(--error-color)" />
                    ) : blueCards.length ? (
                      <CardIcon cardId="blue" fillColor="blue" />
                    ) : null}
                  </S.Stat>
                </S.Stats>
              </S.Person>
            </Fragment>
          );
        })}
      </S.Persons>
    </Container>
  );
};

export default HandballTeam;
