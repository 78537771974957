import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  background-color: var(--base-colors-whiteAlpha-1000);
  /* color: var(--font-color); */
  /* box-shadow: inset 0 -4px 0 var(--border-color); */
  /* border-bottom-width: 4px;
  border-bottom-color: var(--border-color); */
`;
