import { FormControl, FormLabel, Switch } from '../../../chakra';
import * as S from './Host.styled';
import { ELEMENT_IDS } from '@ntb-sport/constants';

interface HostProps {
  host: string;
  onChange: () => void;
  isChecked: boolean;
}

export const Host = ({ host, onChange, isChecked }: HostProps) => {
  return (
    <S.Container data-element-id={ELEMENT_IDS.HEADER}>
      <S.Host>
        Kampen dekkes av <strong>{host}</strong>
      </S.Host>

      <FormControl w="auto" display="flex" alignItems="center">
        <FormLabel htmlFor="toggle-highlights" mb={0} fontSize="xs">
          Kun høydepunkter
        </FormLabel>
        <Switch
          id="toggle-highlights"
          size="sm"
          isChecked={isChecked}
          onChange={onChange}
        />
      </FormControl>
    </S.Container>
  );
};
