import * as S from './TeamMatchResult.styled';

import BackgroundImage from './BackgroundImage/BackgroundImage';
import MatchEvents from './MatchEvents/MatchEvents';
import MatchEventsHandball from './MatchEventsHandball/MatchEventsHandball';
import { MatchesType } from '@ntb-sport/types';
import PenaltyShootout from './PenaltyShootout/PenaltyShootout';
import AggregatedWinner from './AggregatedWinner/AggregatedWinner';
import Team from './Team/Team';
import Result from './Result/Result';
import { COMPONENT_IDS, ELEMENT_IDS, SPORT_ID } from '@ntb-sport/constants';

interface TeamMatchResultProps {
  data: any;
  theme?: any;
  showBackgroundImage?: boolean;
  showTeamColors?: boolean;
  isLoading?: boolean;
  personLink?: ({ id, name }: { id: number; name: string }) => void;
  teamLink?: ({ id, children }: { id: number; children: any }) => void;
  customerConcernId?: number;
  matches: MatchesType;
}

export const TeamMatchResult = ({
  data = {},
  showBackgroundImage,
  showTeamColors,
  isLoading,
  personLink,
  teamLink,
  matches,
}: TeamMatchResultProps) => {
  const {
    sportId,
    homeTeam,
    awayTeam,
    result,
    matchStatusId,
    stage,
    matchEvents,
    timestamp,
    timeStartFirstHalf,
    timeStartSecondHalf,
    timeStartFirstHalfExtraTime,
    timeStartSecondHalfExtraTime,
    stadium,
    matchRelation,
    comment,
  } = data;

  return (
    <S.Container
      data-component-id={COMPONENT_IDS.TEAM_MATCH_RESULT}
      data-tournament-id={stage?.tournament?.id}
      data-element-id={showBackgroundImage ? ELEMENT_IDS.OVERLAY : 'regular'}
    >
      {showBackgroundImage && (
        <BackgroundImage
          src={stadium?.image?.url}
          id={stadium?.image?.id}
          isLoading={isLoading}
        />
      )}

      <S.Content>
        <AggregatedWinner
          matchRelation={matchRelation}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
        />

        <S.Teams>
          <Team
            team={homeTeam}
            isHomeTeam={true}
            matches={matches}
            teamLink={teamLink}
            isLoading={isLoading}
          />

          <Result
            sportId={sportId}
            result={result}
            matchStatusId={matchStatusId}
            timeStartFirstHalf={timeStartFirstHalf}
            timeStartSecondHalf={timeStartSecondHalf}
            timeStartFirstHalfExtraTime={timeStartFirstHalfExtraTime}
            timeStartSecondHalfExtraTime={timeStartSecondHalfExtraTime}
            timestamp={timestamp}
            matchRelation={matchRelation}
            isLoading={isLoading}
          />

          <Team
            team={awayTeam}
            matches={matches}
            teamLink={teamLink}
            isLoading={isLoading}
          />

          {sportId === SPORT_ID.HANDBALL ? (
            <MatchEventsHandball
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              matchEvents={matchEvents}
              personLink={personLink}
            />
          ) : (
            <MatchEvents
              sportId={sportId}
              showTeamColors={showTeamColors}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              matchEvents={matchEvents}
              personLink={personLink}
            />
          )}
          {sportId === SPORT_ID.FOOTBALL && (
            <S.PenaltyShootoutContainer>
              <PenaltyShootout
                matchEvents={matchEvents}
                homeTeamId={homeTeam?.id}
                awayTeamId={awayTeam?.id}
              />
            </S.PenaltyShootoutContainer>
          )}
        </S.Teams>
        <S.MatchComment>{comment}</S.MatchComment>
      </S.Content>
    </S.Container>
  );
};

export default TeamMatchResult;
