import * as S from './IcehockeyTeam.styled';

import { MATCH_EVENTS } from '@ntb-sport/constants';

import { Fragment } from 'react';
import { Container, Tooltip } from '../../../chakra';

const IcehockeyTeam = ({
  team,
  matchEvents,
  oppositionId,
  displayNationality,
}: {
  team: any;
  matchEvents: any;
  oppositionId?: any;
  displayNationality?: boolean;
}) => {
  const keepers = team.persons.filter(
    (person: any) =>
      person.lineNumber === 0 ||
      person.lineNumber === -1 ||
      person.positionIdTeam === 1,
  );

  const lines = team.persons
    .filter(
      (person: any) => person?.lineNumber !== 0 && person?.lineNumber !== -1,
    )
    .reduce(
      (lines: any, person: any) => {
        if (!person.lineNumber) {
          lines['unknown'].persons.push(person);
        } else {
          lines[person.lineNumber].persons.push(person);
        }

        return lines;
      },
      {
        1: { title: '1. Rekke', persons: [] },
        2: { title: '2. Rekke', persons: [] },
        3: { title: '3. Rekke', persons: [] },
        4: { title: '4. Rekke', persons: [] },
        unknown: { title: 'Ukjent rekke', persons: [] },
      },
    );

  return (
    <Container>
      <S.Section>
        <S.Line>
          <S.Persons>
            {keepers.map((person: any, index: number) => {
              const shots = matchEvents.filter(
                (event: any) =>
                  (event.matchEventTypeId === MATCH_EVENTS.COMMENT &&
                    event.person2 &&
                    event.person2.id === person.personId) ||
                  (event.matchEventTypeId === MATCH_EVENTS.GOAL &&
                    event.team &&
                    event.team.id === oppositionId),
              );

              const saves = matchEvents.filter(
                (event: any) =>
                  event.matchEventTypeId === 1 &&
                  event.person2 &&
                  event.person2.id === person.personId,
              );

              return (
                <Fragment key={person.personId}>
                  {index === 0 && (
                    <S.Person>
                      <S.StatTitle>Målvakt</S.StatTitle>
                      <S.StatsHeader>
                        <S.StatHeader>
                          <Tooltip
                            hasArrow
                            label="Skudd mot"
                            aria-label="Skudd mot"
                          >
                            S
                          </Tooltip>
                        </S.StatHeader>
                        <S.StatHeader>
                          <Tooltip
                            hasArrow
                            label="Redninger"
                            aria-label="Redninger"
                          >
                            R
                          </Tooltip>
                        </S.StatHeader>
                        <S.StatHeader>
                          <Tooltip
                            hasArrow
                            label="Redningsprosent"
                            aria-label="Redningsprosent"
                          >
                            %
                          </Tooltip>
                        </S.StatHeader>
                      </S.StatsHeader>
                    </S.Person>
                  )}
                  <S.Person>
                    <S.Bio>
                      <S.ShirtNumber>{person.shirtNumber}</S.ShirtNumber>
                      <S.NameAndCountry>
                        <S.Name>{person.name}</S.Name>
                        {displayNationality && (
                          <S.Country>
                            <S.Flag countryName={person.country.name} />
                            <S.CountryName>{person.country.name}</S.CountryName>
                          </S.Country>
                        )}
                      </S.NameAndCountry>
                    </S.Bio>

                    <S.Stats>
                      <S.Stat>{shots.length}</S.Stat>
                      <S.Stat>{saves.length}</S.Stat>
                      <S.Stat>
                        {Number.isNaN(saves.length / shots.length)
                          ? 0
                          : ((saves.length / shots.length) * 100).toFixed(1)}
                      </S.Stat>
                    </S.Stats>
                  </S.Person>
                </Fragment>
              );
            })}
          </S.Persons>
        </S.Line>
      </S.Section>

      <S.Section>
        {Object.values(lines).map((line: any, index: number) => {
          return (
            <S.Line key={line?.title}>
              <S.Persons>
                {line?.persons?.map((person: any, index: number) => {
                  const goals = matchEvents.filter(
                    (event: any) =>
                      event.matchEventTypeId === MATCH_EVENTS.GOAL &&
                      event.team &&
                      event.team.id === team.id &&
                      event.person &&
                      event.person.id === person.personId,
                  );

                  const assists = matchEvents.filter(
                    (event: any) =>
                      event.matchEventTypeId === MATCH_EVENTS.ASSIST &&
                      event.team &&
                      event.team.id === team.id &&
                      event.person &&
                      event.person.id === person.personId,
                  );

                  const twoMinuteSuspensions = matchEvents.filter(
                    (event: any) =>
                      event.matchEventTypeId ===
                        MATCH_EVENTS.TWO_MINUTES_SUSPENSION &&
                      event.team &&
                      event.team.id === team.id &&
                      event.person &&
                      event.person.id === person.personId,
                  );
                  const fiveMinuteSuspensions = matchEvents.filter(
                    (event: any) =>
                      event.matchEventTypeId ===
                        MATCH_EVENTS.FIVE_MINUTES_SUSPENSION &&
                      event.team &&
                      event.team.id === team.id &&
                      event.person &&
                      event.person.id === person.personId,
                  );
                  const tenMinuteSuspensions = matchEvents.filter(
                    (event: any) =>
                      event.matchEventTypeId ===
                        MATCH_EVENTS.TEN_MINUTES_SUSPENSION &&
                      event.team &&
                      event.team.id === team.id &&
                      event.person &&
                      event.person.id === person.personId,
                  );

                  return (
                    <Fragment key={person.personId}>
                      {index === 0 && (
                        <S.Person>
                          <S.StatTitle>{line?.title}</S.StatTitle>
                          <S.StatsHeader>
                            <S.StatHeader>
                              <Tooltip hasArrow label="Mål" aria-label="Mål">
                                M
                              </Tooltip>
                            </S.StatHeader>
                            <S.StatHeader>
                              <Tooltip
                                hasArrow
                                label="Målgivende"
                                aria-label="Målgivende"
                              >
                                A
                              </Tooltip>
                            </S.StatHeader>
                            <S.StatHeader>
                              <Tooltip
                                hasArrow
                                label="Straffeminutter"
                                aria-label="Straffeminutter"
                              >
                                PIM
                              </Tooltip>
                            </S.StatHeader>
                          </S.StatsHeader>
                        </S.Person>
                      )}
                      <S.Person>
                        <S.Bio>
                          <S.ShirtNumber>{person.shirtNumber}</S.ShirtNumber>
                          <S.NameAndCountry>
                            <S.Name>{person.name}</S.Name>
                            {displayNationality && (
                              <S.Country>
                                <S.Flag countryName={person.country.name} />
                                <S.CountryName>
                                  {person.country.name}
                                </S.CountryName>
                              </S.Country>
                            )}
                          </S.NameAndCountry>
                        </S.Bio>

                        <S.Stats>
                          <S.Stat>{goals.length}</S.Stat>
                          <S.Stat>{assists.length}</S.Stat>
                          <S.Stat>
                            {twoMinuteSuspensions.length * 2 +
                              fiveMinuteSuspensions.length * 5 +
                              tenMinuteSuspensions.length * 10}
                          </S.Stat>
                        </S.Stats>
                      </S.Person>
                    </Fragment>
                  );
                })}
              </S.Persons>
            </S.Line>
          );
        })}
      </S.Section>
    </Container>
  );
};

export default IcehockeyTeam;
