import isNameIncludedInSearchQuery from './isNameIncludedInSearchQuery';

export default function matchIncludesSearchQuery(
  match: any,
  searchQuery: string,
) {
  if (!searchQuery) return true;

  return (
    isNameIncludedInSearchQuery(match.stage.tournament.name, searchQuery) ||
    isNameIncludedInSearchQuery(
      match.stage.tournament.country.name,
      searchQuery,
    ) ||
    isNameIncludedInSearchQuery(match.stage.groupName, searchQuery) ||
    isNameIncludedInSearchQuery(match.name, searchQuery) ||
    isNameIncludedInSearchQuery(match.homeTeam.shortName, searchQuery) ||
    isNameIncludedInSearchQuery(match.awayTeam.shortName, searchQuery)
  );
}
