import {
  AssistIcon as Assist,
  CardIcon as Card,
  GoalIcon as Goal,
  SecondCardIcon as SecondCard,
  SubstituteIcon as Substitute,
} from '../../../../icons';
import styled from 'styled-components';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $position?: any;
  $justify?: any;
  $isClickable?: boolean;
  $isOnPitch?: boolean;
}

export const Container = styled.div``;

export const PitchContainer = styled.div<StyledProps>`
  position: relative;

  @container match-lineup ${MEDIA_QUERY.MOBILE_L} {
    width: 400px;
    margin: 0 auto;
  }
`;

export const Field = styled.div`
  position: absolute;
  width: 100%;
`;

export const Positions = styled.div<StyledProps>`
  position: relative;
  display: grid;
  height: calc(100vw * 1.5);

  padding: 10px 10px 20px 10px;
  grid-template-columns: repeat(5, 20%);
  grid-template-areas:
    'LW LS ST RS RW'
    ' AML AMCL AMC AMCR AMR '
    '  ML MCL MC MCR MR'
    '  DML DMCL DMC DMCR DMR'
    ' LB DCL DC DCR RB'
    '. . K .  .';
  align-items: flex-end;
  justify-items: center;

  @container match-lineup ${MEDIA_QUERY.MOBILE_L} {
    height: 600px;
  }
`;

export const BenchContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @container match-lineup ${MEDIA_QUERY.MOBILE_L} {
    width: 400px;
    margin: 0 auto;
  }
`;

export const BenchTitle = styled.h5`
  margin: 0 0 var(--ntb-space-4) 0;
  color: var(--base-colors-blackAlpha-900);
`;

export const Bench = styled.div`
  padding: 0 var(--ntb-space-4);
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Position = styled.div<StyledProps>`
  width: 100%;
  background: none;
  border: none;
  text-decoration: none;
  display: grid;
  grid-template-rows: 55px 18px 22px;
  align-items: flex-start;
  grid-area: ${(props) => props?.$position};
  margin-left: ${(props) => props?.$justify};
  justify-items: center;
  padding: 0;

  > svg {
    margin: 0 auto;
  }

  cursor: ${(props) => props?.$isClickable && 'pointer'};
`;

export const BenchPerson = styled.div<StyledProps>`
  background: none;
  border: none;
  cursor: ${(props) => props?.$isClickable && 'pointer'};
  display: grid;
  grid-template-rows: 55px 18px 22px;
  align-items: center;
  justify-items: center;
  text-decoration: none;
`;

export const ShirtName = styled.span<StyledProps>`
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  color: var(--font-color);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const Events = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--ntb-radii-md);
`;

export const Event = styled.div`
  display: flex;
  align-items: center;
`;

export const Time = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const GoalIcon = styled(Goal)`
  color: #000;
  background: #fff;
  border-radius: 50%;
`;

export const OwnGoalIcon = styled(Goal)`
  color: var(--base-colors-error);
  background: #fff;
  border-radius: 50%;
`;

export const AssistIcon = styled(Assist)`
  color: #000;
  background: #fff;
  border-radius: 50%;
`;

export const YellowCardIcon = styled(Card).withConfig({
  shouldForwardProp: (prop) => ['fillColor', 'cardId'].includes(prop),
})``;

export const SecondYellowCardIcon = styled(SecondCard).withConfig({
  shouldForwardProp: (prop) =>
    ['fillColor1', 'fillColor2', 'cardId'].includes(String(prop)),
})``;

export const RedCardIcon = styled(Card).withConfig({
  shouldForwardProp: (prop) => ['fillColor', 'cardId'].includes(prop),
})``;

export const SubstititeIcon = styled(Substitute).withConfig({
  shouldForwardProp: (prop) =>
    ['fillColor1', 'fillColor2'].includes(String(prop)),
})``;
