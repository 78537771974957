import styled from 'styled-components';

export const Calendar = styled.div`
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background-color);
  color: var(--font-color);
  z-index: 99;
  padding: var(--ntb-space-2);
`;
