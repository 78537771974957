import { COMPONENT_IDS } from '@ntb-sport/constants';

import { useMemo } from 'react';

import Tournaments from './Tournaments/Tournaments';
import { Loader } from './Loader';
import tournamentIncludesSearchQuery from './helpers/tournamentIncludesSearchQuery';

export interface TeamCalendarProps {
  data: any;
  onMatchClick?: any;
  customerConcernId: any;
  onClickSubscribeCompetition?: any;
  onClickSubscribeMatch?: any;
  onClickStandings?: any;
  subscribedTournaments?: number[];
  subscribedMatches?: number[];
  isLoading: boolean;
  sportGroup: string;
  searchQuery: string;
  filterSubscribedMatches?: boolean;
}

export const TeamCalendar = ({
  data,
  onMatchClick,
  customerConcernId,
  onClickSubscribeMatch,
  onClickStandings,
  subscribedMatches,
  isLoading,
  sportGroup,
  searchQuery,
  filterSubscribedMatches,
}: TeamCalendarProps) => {
  const cachedTournamentIncludesSearchQuery = useMemo(
    () => tournamentIncludesSearchQuery,
    [searchQuery],
  );

  if (!data) return null;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div data-component-id={COMPONENT_IDS.TEAM_CALENDAR}>
      <Tournaments
        tournaments={Object.values(data)
          ?.filter((tournament: any) =>
            cachedTournamentIncludesSearchQuery(tournament, searchQuery),
          )
          ?.sort((a: any, b: any) => a.priority - b.priority)}
        sportGroup={sportGroup}
        onClickStandings={onClickStandings}
        debouncedSearchQuery={searchQuery}
        customerConcernId={customerConcernId}
        onMatchClick={onMatchClick}
        onClickSubscribeMatch={onClickSubscribeMatch}
        subscribedMatches={subscribedMatches}
        filterSubscribedMatches={filterSubscribedMatches}
      />
    </div>
  );
};

export default TeamCalendar;
